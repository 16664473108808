import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

class PlaylistBanner extends Component {
	render() {
    const { id, name, image } = this.props;

		return (
      <div className="playlist-banner">
        <Link to={`/dashboard/playlist/${id}`}>
          <img src={image} alt={name} />
        </Link>
      </div>
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

PlaylistBanner.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistBanner);
