import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import "./style.scss";

class Header extends Component {
  render() {
    if (!isMobile) {
      var request = (
        <div className="request-access-container">
          <a href="https://www.moodswingmusic.io/#request">
            Request Access
          </a>
        </div>
      );
    } else {
      var request = null;
    }

    return (
      <>
        <section className="header">
          <div className="container is-fluid">
            <div
              className="columns"
              style={{ justifyContent: "space-between" }}
            >
              <div className="logo-container">
                <a href="http://moodswingmusic.io">
                  <img
                    src="/img/logo-white/moodswing-logo-white.png"
                    alt="Moodswing.io"
                    className="logo"
                  />
                </a>
              </div>
              {request}
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
