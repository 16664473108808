import React, { Component } from "react";
import { connect } from "react-redux";
import Category from "../Category";

import "./style.scss";

class BestFor extends Component {
  render() {
    return (
      <Category
        category="BestFor"
        banner="/img/banner_genre.jpg"
        categoryName="Best For"
        categoryTitle="Best For"
        categoryLastUpdated="4 Days Ago"
        categoryDescription=""
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BestFor);
