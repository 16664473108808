import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as localCache from "../../utils/localCache";
import { togglePlayPause } from "../../actions/audioPlayer";
import { cacheRecent } from "../../actions/cache";
import { addLockerItem, removeLockerItem } from "../../API/lockers";
import { addDownloadsItem } from "../../API/downloads";
import ReactTooltip from "react-tooltip";
import proImg from "../../Components/ProButton/Pro.png";

import "./style.scss";

class Playlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locker: [],
      showDownloadModal: false,
      expand: false,
      searchTag: "",
      redirectToSearch: false,
    };
  }

  componentDidUpdate() {
    const { redirectToSearch } = this.state;

    if (redirectToSearch) {
      this.setState({
        redirectToSearch: false,
      });
    }
  }

  play = () => {
    this.props.onPlay();
  };

  toggleExpand = () => {
    const { expand } = this.state;
    this.setState({
      expand: !expand,
    });
  };

  openDownloadModal = () => {
    const { user, id } = this.props;

    this.setState({
      showDownloadModal: true,
    });

    const userId = user.id;
    const trackId = id;

    addDownloadsItem({
      user: userId,
      track: trackId,
    });
  };

  closeDownloadModal = () => {
    this.setState({
      showDownloadModal: false,
    });
  };

  toggleLocker = () => {
    const { updateLocker, locker, id, user } = this.props;

    const lockerItem = locker.filter((item) => item.Track_ID === id);

    if (lockerItem && lockerItem.length > 0) {
      removeLockerItem({
        user: user.id,
        track: lockerItem[0].Record_ID,
      }).then(updateLocker);
    } else {
      addLockerItem({
        user: user.id,
        track: id,
      }).then(updateLocker);
    }
  };

  getTagContent = () => {
    const {
      track: {
        fields: {
          "Tag: Genre": genreTags,
          "Tag: Mood": moodTags,
          "Tag: Track Type": trackTypeTags,
          "Tag: Geography": geographyTags,
          "Tag: Intrument": instrumentTags,
        },
      },
    } = this.props;
    const trackTags = [
      genreTags,
      moodTags,
      trackTypeTags,
      geographyTags,
      instrumentTags,
    ].reduce((acc, tags) => {
      if (tags && tags.length) {
        return [...acc, ...tags];
      }
      return acc;
    }, []);
    if (trackTags && trackTags.length) {
      const tags = localCache.getItem("cache-tags");
      const tagsWithOrder = tags
        // Ignore tags without an order
        .filter(({ fields: { "Custom Tag Order": order } }) => !!order)
        // Extract name, order and color
        .map(
          ({
            id,
            fields: {
              Name: name,
              "Custom Tag Order": order,
              "Tag Color": color,
            },
          }) => ({ id, name, order, color })
        )
        // Sort by order
        .sort(({ order: orderA }, { order: orderB }) => {
          if (orderA < orderB) return -1;
          if (orderA > orderB) return 1;
          return 0;
        });
      return tagsWithOrder.map(({ id, name, color }) => {
        if (trackTags.includes(id)) {
          return (
            <button
              onClick={this.handleTagClick(name.toLowerCase())}
              className="tag-name"
              style={{ color }}
            >
              {`#${name.toLowerCase().split(" ").join("")}`}
            </button>
          );
        }
        return null;
      });
    }
    return <></>;
  };

  handleTagClick = (tag) => () => {
    this.setState({
      searchTag: tag,
      redirectToSearch: true,
    });
  };

  showRedirect = () => {
    const { redirectToSearch, searchTag } = this.state;
    return (
      redirectToSearch && (
        <Redirect
          to={`/dashboard/filter/${encodeURI(searchTag)}`}
          push={true}
        />
      )
    );
  };

  render() {
    const {
      audioPlayer,
      track,
      locker,
      id,
      url,
      name,
      producer,
      bpm,
      cultureTags,
      showLockerBtn = true,
      showDownloadBtn = true,
    } = this.props;
    const { showDownloadModal, expand } = this.state;

    return (
      <>
        {this.showRedirect()}
        <tr
          className={classNames({
            "is-expanded": expand,
          })}
        >
          <td className="play">
            <div className="play-icon">
              {audioPlayer &&
                audioPlayer.url === url &&
                audioPlayer.playState === true && (
                  <img
                    src="/img/playlist_play_btn_active.svg"
                    onClick={this.play}
                    alt="Play"
                  />
                )}
              {(!audioPlayer ||
                !audioPlayer.url ||
                audioPlayer.url !== url ||
                audioPlayer.playState === false) && (
                <img
                  src="/img/playlist_play_btn.svg"
                  onClick={this.play}
                  alt="Play"
                />
              )}
            </div>
            <div
              className="open-icon"
              style={{
                transform: expand ? "rotate(45deg)" : "rotate(0deg)",
                color: expand ? "#ffffff" : "#808080",
              }}
              onClick={this.toggleExpand}
            >
              +
            </div>
          </td>
          <td
            className="name"
            onClick={this.toggleExpand}
            data-tip={this.props.pro ? "Pro: Contact for usage." : null}
          >
            {name}

            {this.props.pro ? (
              <img className="proImg-class" src={proImg} />
            ) : (
              ""
            )}
          </td>
          <td className="producer" onClick={this.toggleExpand}>
            {producer}
          </td>
          <td className="bpm" onClick={this.toggleExpand}>
            {bpm}
          </td>
          <td
            className={`locker ${showLockerBtn ? "" : "disabled"}`}
            data-tip={
              !showDownloadBtn
                ? "This feature is not available for Demo users."
                : null
            }
          >
            <>
              {locker.some((item) => item.Track_ID === id) ? (
                <img
                  src="/img/playlist_favorite_btn_active.svg"
                  onClick={() => {
                    showLockerBtn && this.toggleLocker();
                  }}
                  alt="Remove from Locker"
                />
              ) : (
                <img
                  src="/img/playlist_favorite_btn.svg"
                  onClick={() => {
                    showLockerBtn && this.toggleLocker();
                  }}
                  alt="Add to Locker"
                />
              )}
              <ReactTooltip />
            </>
          </td>
          {/* download disabled pro - JUST TOOLTOP NO MODAL */}
          <td
            className={`download ${showDownloadBtn ? "" : "disabled"}`}
            data-tip={
              !showDownloadBtn
                ? "This feature is not available for Demo users."
                : null
            }
          >
            <img
              src="/img/playlist_download_btn.svg"
              alt="Download"
              onClick={
                !this.props.pro
                  ? () => {
                      showDownloadBtn && this.openDownloadModal();
                    }
                  : null
              }
              data-tip={this.props.pro ? "Pro: Contact for usage." : null}
            />
            <ReactTooltip />

            <div
              className={classNames({
                modal: true,
                "is-active": showDownloadModal,
              })}
            >
              <div
                className="modal-background"
                onClick={this.closeDownloadModal}
              ></div>
              <button
                className="modal-close is-large"
                aria-label="close"
                onClick={this.closeDownloadModal}
              ></button>

              <div className="modal-card">
                <section className="modal-card-body">
                  <span style={{ color: "#000000" }}>
                    <strong>
                      By downloading this track, you agree to all terms and
                      conditions set forth in the user agreement connected to
                      your login. Copyright MoodswingMusic.iO 2021 Ⓒ All Rights
                      Reserved.
                    </strong>
                    <br />
                    <a
                      href={`/download?f=${url}`}
                      className="button"
                      style={{ backgroundColor: "#33afff", color: "#ffffff" }}
                    >
                      Download
                      {/* {track.fields["Pro"].toString()} */}
                    </a>
                  </span>
                </section>
                <footer className="modal-card-foot">
                  <button
                    className="button is-success"
                    onClick={this.closeDownloadModal}
                  >
                    Done
                  </button>
                </footer>
              </div>
            </div>
          </td>
        </tr>
        {expand && (
          <tr className="expand">
            <td className="tag-cell-padding"></td>
            <td colSpan="5" className="tags-cell">
              <p className="license">
                {track.fields["Track Description"] || ""}
              </p>
              {this.getTagContent()}
            </td>
          </tr>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  audioPlayer: state.audioPlayer,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  togglePlayPause: () => dispatch(togglePlayPause()),
  cacheRecent: (recent, timestamp) => dispatch(cacheRecent(recent, timestamp)),
});

Playlist.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.array,
  url: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
