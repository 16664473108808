import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

class PlaylistThumbnail extends Component {
	render() {
    const { id, name, image, style } = this.props;

		return (
      <div className="playlist-thumbnail">
        <Link to={`/dashboard/playlist/${id}`}>
          <img src={image} alt={name} style={style} />
          <span className="playlist-title">{name}</span>
        </Link>
      </div>
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

PlaylistThumbnail.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistThumbnail);
