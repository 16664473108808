const initialState = {
  tags: [],
  producers: [],
  playlists: [],
  recents: [],
};

const cache = (state = initialState, action) => {
  
  switch (action.type) {
    case "CACHE_TAGS":
      return Object.assign({}, state, {
        tags: action.tags,
      });

    case "CACHE_PRODUCERS":
      return Object.assign({}, state, {
        producers: action.producers,
      });

    case "CACHE_PLAYLISTS":
      return Object.assign({}, state, {
        playlists: action.playlists,
      });

    case "CACHE_GENRES":
      return Object.assign({}, state, {
        genres: action.genres,
      });

    case "CACHE_MOODS":
      return Object.assign({}, state, {
        moods: action.moods,
      });

    case "CACHE_GEOGRAPHIES":
      return Object.assign({}, state, {
        geographies: action.geographies,
      });

    case "ADD_TO_RECENTS_CACHE":
      const { song, timestamp } = action;
      return Object.assign({}, state, {
        recents: [
          ...state.recents,
          {
            song,
            timestamp,
          },
        ],
      });

    default:
      return state;
  }
};

export default cache;
