import { combineReducers } from 'redux';
import audioPlayer from './audioPlayer';
import cache from './cache';
import user from './user';

export default combineReducers({
  audioPlayer,
  cache,
  user,
});
