import axios from 'axios';

const TABLE_NAME = '_downloads';

const addDownloadsItem = ({ user, track }) => {
  return axios.post(TABLE_NAME, {
    fields: {
      Auth0_User_ID: user,
      Track: [track],
    }
  });
};

const getDownloadsByUser = user => {
  return axios.get(TABLE_NAME, {
    params: {
      fields: ['Track'],
      filterByFormula: `FIND("${user}", {Auth0_User_ID}) > 0`,
    },
  }).then(({ data }) => data.records);
};

export { addDownloadsItem, getDownloadsByUser };
