import axios from 'axios';

const TABLE_NAME = '_producers';

const getProducers = () => {
  return axios.get(TABLE_NAME)
    .then(({ data }) => data.records);
};

const getProducersById = id => {
  return axios.get(TABLE_NAME, {
    params: {
      maxRecords: 1,
      filterByFormula: `{Record_ID} = "${id}"`,
    },
  }).then(({ data }) => data.records);
};

export { getProducers, getProducersById };
