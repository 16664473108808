import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';

class Culture extends Component {
	render() {
		return (
      <>
        <h1>Culture</h1>
      </>
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Culture);
