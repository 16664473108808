import React, { Component } from "react";
import { connect } from "react-redux";
import PlaylistBanner from "../Playlist/playlistBanner";
import PlaylistThumbnail from "../Playlist/playlistThumbnail";
import AliceCarousel from "react-alice-carousel";
import * as localCache from "../../utils/localCache";
import { getPlaylists } from "../../API/playlists";
import _ from "lodash";

import "./style.scss";

class Featured extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlists: [],
      headerChevronTop: 0,
    };

    this.headerCarouselEl = null;
  }

  componentDidMount() {
    this.headerCarouselEl = document.querySelector(".alice-carousel__wrapper");
    setTimeout(() => {
      this.setState({
        headerChevronTop: this.headerCarouselEl.offsetHeight / 2,
      });
    }, 300);
  }

  responsive = {
    0: { items: 1 },
    440: { items: 2 },
    768: { items: 3 },
    1024: { items: 4 },
  };

  componentWillMount = () => {
    const playlists = localCache.getItem("cache-playlists");

    if (playlists) {
      this.setState({ playlists });
    } else {
      getPlaylists().then((playlists) => {
        this.setState({ playlists });
      });
    }
  };

  getBanner = (playlist) => {
    return playlist.fields["Banner Image"] &&
      playlist.fields["Banner Image"].length > 0
      ? playlist.fields["Banner Image"][0].url
      : "http://placehold.it/600/200";
  };

  getThumbnail = (playlist) => {
    return playlist.fields["Thumbnail Image"] &&
      playlist.fields["Thumbnail Image"].length > 0
      ? playlist.fields["Thumbnail Image"][0].url
      : "http://placehold.it/200/200";
  };

  handleResizeOfMainBanner = (event) => {
    this.setState({
      headerChevronTop: this.headerCarouselEl.offsetHeight / 2,
    });
  };

  render() {
    const { playlists, headerChevronTop } = this.state;
    let banners = [];

    if (playlists.length > 0) {
      banners = playlists.filter((playlist) => {
        return playlist.fields.Featured === true;
      });
    }

    return (
      <div className="featured">
        {banners.length > 0 && (
          <AliceCarousel
            buttonsDisabled
            onResized={this.handleResizeOfMainBanner}
            ref={(el) => (this.HeaderCarousel = el)}
            items={_.orderBy(
              banners,
              (record) => record.fields["Custom Order"] || 0
            ).map((playlist) => (
              <div className="playlist-banner-wrapper">
                <PlaylistBanner
                  id={playlist.id}
                  name={playlist.fields.Name}
                  image={this.getBanner(playlist)}
                  key={playlist.id}
                />
              </div>
            ))}
          ></AliceCarousel>
        )}
        {headerChevronTop && (
          <>
            <img
              src="/img/left-chevron.png"
              alt="Previous Slide"
              onClick={() => this.HeaderCarousel.slidePrev()}
              style={{
                position: "absolute",
                top: headerChevronTop - 60 / 2,
                left: 20,
                height: 60,
              }}
            />

            <img
              src="/img/right-chevron.png"
              alt="Next Slide"
              onClick={() => this.HeaderCarousel.slideNext()}
              style={{
                position: "absolute",
                top: headerChevronTop - 60 / 2,
                right: 20,
                height: 60,
              }}
            />
          </>
        )}

        <h1>Mood</h1>
        <div className="columns">
          <div className="column is-1 chevron-container-left">
            <img
              alt="Previous Slide"
              src="/img/left-chevron.png"
              onClick={() => this.MoodCarousel.slidePrev()}
            />
          </div>
          <div className="column is-10">
            <div style={{ padding: 0.3 }}>
              <AliceCarousel
                responsive={this.responsive}
                ref={(el) => (this.MoodCarousel = el)}
                buttonsDisabled
                items={_.orderBy(
                  playlists,
                  (record) => record.fields["Custom Order"] || 0
                )
                  .filter((p) => p.fields.Type === "Mood")
                  .map((mood) => (
                    <PlaylistThumbnail
                      id={mood.id}
                      name={mood.fields.Name}
                      image={this.getThumbnail(mood)}
                      key={mood.id}
                    />
                  ))}
              ></AliceCarousel>
            </div>
          </div>
          <div className="column is-1 chevron-container-right">
            <img
              alt="Next Slide"
              src="/img/right-chevron.png"
              onClick={() => this.MoodCarousel.slideNext()}
            />
          </div>
        </div>

        <h1>Genre</h1>
        <div className="columns">
          <div className="column is-1 chevron-container-left">
            <img
              alt="Previous Slide"
              src="/img/left-chevron.png"
              onClick={() => this.GenreCarousel.slidePrev()}
            />
          </div>
          <div className="column is-10">
            <div style={{ padding: 0.3 }}>
              <AliceCarousel
                responsive={this.responsive}
                ref={(el) => (this.GenreCarousel = el)}
                buttonsDisabled
                items={_.orderBy(
                  playlists,
                  (record) => record.fields["Custom Order"] || 0
                )
                  .filter((p) => p.fields.Type === "Genre")
                  .map((genre) => (
                    <PlaylistThumbnail
                      id={genre.id}
                      name={genre.fields.Name}
                      image={this.getThumbnail(genre)}
                      key={genre.id}
                    />
                  ))}
              ></AliceCarousel>
            </div>
          </div>
          <div className="column is-1 chevron-container-right">
            <img
              alt="Next Slide"
              src="/img/right-chevron.png"
              onClick={() => this.GenreCarousel.slideNext()}
            />
          </div>
        </div>

        <h1>Best For</h1>
        <div className="columns">
          <div className="column is-1 chevron-container-left">
            <img
              alt="Previous Slide"
              src="/img/left-chevron.png"
              onClick={() => this.BestForCarousel.slidePrev()}
            />
          </div>
          <div className="column is-10">
            <div style={{ padding: 0.3 }}>
              <AliceCarousel
                responsive={this.responsive}
                ref={(el) => (this.BestForCarousel = el)}
                buttonsDisabled
                items={_.orderBy(
                  playlists,
                  (record) => record.fields["Custom Order"] || 0
                )
                  .filter((p) => p.fields.Type === "BestFor")
                  .map((BestFor) => (
                    <PlaylistThumbnail
                      id={BestFor.id}
                      name={BestFor.fields.Name}
                      image={this.getThumbnail(BestFor)}
                      key={BestFor.id}
                    />
                  ))}
              ></AliceCarousel>
            </div>
          </div>
          <div className="column is-1 chevron-container-right">
            <img
              alt="Next Slide"
              src="/img/right-chevron.png"
              onClick={() => this.BestForCarousel.slideNext()}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Featured);
