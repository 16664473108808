import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as localCache from "../../utils/localCache";
import { togglePlayPause } from "../../actions/audioPlayer";
import { cacheRecent } from "../../actions/cache";
import ReactTooltip from "react-tooltip";
import proImg from "../../Components/ProButton/Pro.png";
import { isMobile } from "react-device-detect";

import "./style.scss";

class Playlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDownloadModal: false,
      expand: false,
    };
  }

  componentDidUpdate() {
    const { redirectToSearch } = this.state;

    if (redirectToSearch) {
      this.setState({
        redirectToSearch: false,
      });
    }
  }

  play = () => {
    this.props.onPlay();
  };

  toggleExpand = () => {
    const { expand } = this.state;
    this.setState({
      expand: !expand,
    });
  };

  openDownloadModal = () => {
    this.setState({
      showDownloadModal: true,
    });
  };

  closeDownloadModal = () => {
    this.setState({
      showDownloadModal: false,
    });
  };

  getTagContent = () => {
    const {
      track: {
        fields: {
          "Tag: Genre": genreTags,
          "Tag: Mood": moodTags,
          "Tag: Track Type": trackTypeTags,
          "Tag: Geography": geographyTags,
          "Tag: Intrument": instrumentTags,
        },
      },
    } = this.props;
    const trackTags = [
      genreTags,
      moodTags,
      trackTypeTags,
      geographyTags,
      instrumentTags,
    ].reduce((acc, tags) => {
      if (tags && tags.length) {
        return [...acc, ...tags];
      }
      return acc;
    }, []);
    if (trackTags && trackTags.length) {
      const tags = localCache.getItem("cache-tags");
      const tagsWithOrder = tags
        // Ignore tags without an order
        .filter(({ fields: { "Custom Tag Order": order } }) => !!order)
        // Extract name, order and color
        .map(
          ({
            id,
            fields: {
              Name: name,
              "Custom Tag Order": order,
              "Tag Color": color,
            },
          }) => ({ id, name, order, color })
        )
        // Sort by order
        .sort(({ order: orderA }, { order: orderB }) => {
          if (orderA < orderB) return -1;
          if (orderA > orderB) return 1;
          return 0;
        });
      return tagsWithOrder.map(({ id, name, color }) => {
        if (trackTags.includes(id)) {
          return (
            <button className="tag-name" style={{ color }}>
              {`#${name.toLowerCase().split(" ").join("")}`}
            </button>
          );
        }
        return null;
      });
    }
    return <></>;
  };

  render() {
    const {
      pro,
      playlistFields,
      audioPlayer,
      track,
      url,
      name,
      producer,
      bpm,
      cultureTags,
      showLockerBtn = true,
      showDownloadBtn = true,
    } = this.props;
    const { showDownloadModal, expand } = this.state;

    const allowPrivateDownload =
      playlistFields &&
      playlistFields["Allow Private Download"] &&
      !this.props.pro
        ? playlistFields["Allow Private Download"]
        : false;

    // <td
    //           className={"download " + (allowPrivateDownload ? "" : "disabled")}
    //           data-tip={
    //             // !allowPrivateDownload
    //             //   ? "Request Beta Access to enable download."
    //             //   : null
    //             this.props.pro ? "Pro: Contact for usage" : !allowPrivateDownload ? : "Request Beta Access to enable download." : null
    //           }
    //         >

    // const downloadDatatip;

    // if (this.props.pro) {
    //   downloadDatatip = "Pro: Contact for usage";
    // } else if ()

    return (
      <>
        <tr
          className={classNames({
            "is-expanded": expand,
          })}
        >
          <td className="play">
            <div className="play-icon">
              {audioPlayer &&
                audioPlayer.url === url &&
                audioPlayer.playState === true && (
                  <img
                    src="/img/playlist_play_btn_active.svg"
                    onClick={this.play}
                    alt="Play"
                  />
                )}
              {(!audioPlayer ||
                !audioPlayer.url ||
                audioPlayer.url !== url ||
                audioPlayer.playState === false) && (
                <img
                  src="/img/playlist_play_btn.svg"
                  onClick={this.play}
                  alt="Play"
                />
              )}
            </div>
            <div
              className="open-icon"
              style={{
                transform: expand ? "rotate(45deg)" : "rotate(0deg)",
                color: expand ? "#ffffff" : "#808080",
              }}
              onClick={this.toggleExpand}
            >
              +
            </div>
          </td>
          <td
            className="name"
            onClick={this.toggleExpand}
            data-tip={this.props.pro ? "Pro: Contact for usage." : null}
          >
            {name}
            {this.props.pro ? (
              <img className="proImg-class" src={proImg} />
            ) : (
              ""
            )}
          </td>
          <td className="producer" onClick={this.toggleExpand}>
            {producer}
          </td>
          <td className="bpm" onClick={this.toggleExpand}>
            {bpm}
          </td>
          {showLockerBtn && (
            <td
              className="locker disabled"
              data-tip="Request Beta Access to enable locker."
            >
              <img
                src="/img/playlist_favorite_btn.svg"
                alt="Add to Locker (Disabled)"
              />
            </td>
          )}
          {showLockerBtn && showDownloadBtn && <td className="spacer"></td>}
          {showDownloadBtn && (
            <td
              className={"download " + (allowPrivateDownload ? "" : "disabled")}
              data-tip={
                this.props.pro
                  ? "Pro: Contact for usage"
                  : !allowPrivateDownload
                  ? "Request Beta Access to enable download."
                  : null
              }
            >
              <ReactTooltip />
              <img
                src="/img/playlist_download_btn.svg"
                alt="Download"
                onClick={
                  allowPrivateDownload ? this.openDownloadModal : undefined
                }
              />

              <div
                className={classNames({
                  modal: true,
                  "is-active": showDownloadModal,
                })}
              >
                <div
                  className="modal-background"
                  onClick={this.closeDownloadModal}
                ></div>
                <button
                  className="modal-close is-large"
                  aria-label="close"
                  onClick={this.closeDownloadModal}
                ></button>

                <div className="modal-card">
                  <section className="modal-card-body">
                    <span style={{ color: "#000000" }}>
                      <strong>
                        By downloading this track, you agree to all terms and
                        conditions set forth in the user agreement connected to
                        your login. Copyright MoodswingMusic.iO 2021 Ⓒ All
                        Rights Reserved.
                      </strong>
                      <br />
                      <a
                        href={`/download?f=${url}`}
                        className="button"
                        style={{ backgroundColor: "#33afff", color: "#ffffff" }}
                      >
                        Download
                      </a>
                    </span>
                  </section>
                  <footer className="modal-card-foot">
                    <button
                      className="button is-success"
                      onClick={this.closeDownloadModal}
                    >
                      Done
                    </button>
                  </footer>
                </div>
              </div>
            </td>
          )}
        </tr>
        {expand && (
          <tr className="expand">
            <td className="expand-spacer"></td>
            <td colSpan="5" className="tags-cell">
              <p className="license">
                {track.fields["Track Description"] || ""}
              </p>
              {this.getTagContent()}
            </td>
          </tr>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  audioPlayer: state.audioPlayer,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  togglePlayPause: () => dispatch(togglePlayPause()),
  cacheRecent: (recent, timestamp) => dispatch(cacheRecent(recent, timestamp)),
});

Playlist.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  tags: PropTypes.array,
  url: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
