import axios from 'axios';

const getTagsFromTable = async (tableName) => {
  let allTags = [];
  let offset = null;
  do {
    const { data: { records, offset: callOffset } } = await axios.get(tableName, {
      params: offset ? {
        offset,
      } : null
    });
    offset = callOffset
    allTags.push(...records);
  } while (Boolean(offset));
  return allTags;
};

const getTags = async () => {
  const tagTypes = ['genre', 'mood', 'track_type', ' geography', ' instrument'];
  const allTags = [];
  for (let i = 0; i < tagTypes.length; i++) {
    const tagName = tagTypes[i];
    const tags = await getTagsFromTable(`_tag_${tagName}`);
    allTags.push(...tags);
  }
  return allTags;
};

export { getTags };
