const initialState = {
  url: "",
  waveColor: "#505050",
  progressColor: "#ffffff",
  playState: false,
};

const audioPlayer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SONG":
      return Object.assign({}, state, {
        id: action.id,
        producer: action.producer,
        name: action.name,
        bpm: action.bpm,
        url: action.url,
        pro: action.pro,
        tags: action.tags,
        playlist: action.playlist,
        playState: true,
      });

    case "TOGGLE_PLAY_PAUSE":
      return Object.assign({}, state, {
        playState: !state.playState,
      });

    case "CHANGE_WAVE_COLOR":
      return Object.assign({}, state, {
        waveColor: action.waveColor,
      });

    case "CHANGE_PROGRESS_COLOR":
      return Object.assign({}, state, {
        progressColor: action.progressColor,
      });
    default:
      return state;
  }
};

export default audioPlayer;
