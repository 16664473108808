import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PlaylistTable from '../Playlist/playlistTable';
import ContentBanner from '../Category/ContentBanner';
import { getDownloadsByUser } from '../../API/downloads';
import { getTracksByIds } from '../../API/tracks';

import './style.scss';

class Downloads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trackList: [],
      latestTrackDate: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      this.updateTrackList();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user.id !== user.id) {
      this.updateTrackList();
    }
  };

  updateTrackList = () => {
    const { user } = this.props;
    getDownloadsByUser(user.id)
      .then(records => {
        const latestTrack = _.orderBy(records, ['createdTime'], 'desc');

        if (latestTrack && latestTrack.length > 0) {
          this.setState({
            latestTrackDate: moment(latestTrack[0].createdTime).fromNow()
          });
        }

        const ids = records
          .filter(record => record && record.fields && record.fields.Track)
          .map(record => {
            return record.fields.Track[0];
          });

        getTracksByIds({ ids })
          .then(trackList => {
            this.setState({ trackList });
          });
      });
  };

	render() {
    const { trackList, latestTrackDate } = this.state;

		return (
      <div className="playlist-page">
        <ContentBanner
          banner={'/img/banner_downloads.jpg'}
          categoryName="Downloads"
          categoryTitle="Downloads"
          categoryDescription="" />

        <PlaylistTable playlistSongs={trackList} showLockerBtn={false} />
      </div>
		);
	}
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
