import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getPlaylistSongsById } from "../../API/playlistSongs";
import { getTracksByIds } from "../../API/tracks";
import PlaylistTable from "../Playlist/playlistTable";
import ContentBanner from "../Category/ContentBanner";

import "./style.scss";

class Playlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlistSongs: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    getPlaylistSongsById(match.params.id).then((playlistSongsTable) => {
      const ids = _.map(playlistSongsTable, (p) => p.fields.Track_Rollup);
      getTracksByIds({ ids }).then((playlistSongsResponse) => {
        const playlistSongs = [];
        for (let i = 0; i < playlistSongsTable.length; i++) {
          for (let j = 0; j < playlistSongsResponse.length; j++) {
            if (
              playlistSongsTable[i].fields["Track_Rollup"] ===
              playlistSongsResponse[j].id
            ) {
              playlistSongs.push(playlistSongsResponse[j]);
            }
          }
        }

        this.setState({ playlistSongs });
      });
    });
  }

  render() {
    const { match, cache, user } = this.props;
    const { playlistSongs } = this.state;
    const playlist = _.find(cache.playlists, (playlist) => {
      return playlist.id === match.params.id;
    });
    const banner =
      playlist.fields["Banner Image"] && playlist.fields["Banner Image"][0]
        ? playlist.fields["Banner Image"][0].url
        : "/img/banner_mood.jpg";
    const categoryName = `${playlist.fields.Type} / ${playlist.fields.Name}`;
    const categoryTitle = playlist.fields.Name;
    const categoryDescription = playlist.fields.Description;

    const DEMO_USER_ROLE_ID = "rol_WNHLm2ewgNZDv1eH";
    const FULL_USER_ROLE_ID = "rol_Saqeoh9Jrd7m5mUN";

    const showDownloadBtn = user.hasRole(FULL_USER_ROLE_ID);
    const showLockerBtn = user.hasRole(FULL_USER_ROLE_ID);

    return (
      <div className="playlist-page">
        {playlist && playlist.fields && !playlist.fields["Featured"] && (
          <ContentBanner
            banner={banner}
            categoryName={categoryName}
            categoryTitle={categoryTitle}
            categoryLastUpdated={""}
            categoryDescription={categoryDescription}
          />
        )}
        {playlist && playlist.fields && playlist.fields["Featured"] && (
          <ContentBanner
            banner={banner}
            categoryName={""}
            categoryTitle={""}
            categoryLastUpdated={""}
            categoryDescription={""}
          />
        )}

        <PlaylistTable
          playlistSongs={playlistSongs}
          showDownloadBtn={showDownloadBtn}
          showLockerBtn={showLockerBtn}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  cache: state.cache,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
