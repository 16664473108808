import axios from "axios";

const TABLE_NAME = "_playlists";

const getPlaylists = () => {
  return axios.get(TABLE_NAME).then(({ data }) => data.records);
};

const getPlaylistsById = (id) => {
  return axios
    .get(TABLE_NAME, {
      params: {
        maxRecords: 1,
        filterByFormula: `{Record_ID} = "${id}"`,
      },
    })
    .then(({ data }) => data.records);
};

export { getPlaylists, getPlaylistsById };
