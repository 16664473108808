import React, { Component } from 'react';
import { connect } from 'react-redux';
import Category from '../Category';

import './style.scss';

class Geography extends Component {
	render() {
		return (
      <Category
        category="Geography"
        banner="/img/banner_geography.jpg"
        categoryName="Geography"
        categoryTitle="Geography"
        categoryLastUpdated="4 Days Ago"
        categoryDescription=""
        playlistTitle="Regions"
        />
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Geography);
