import axios from 'axios';

const TABLE_NAME = '_lockers';

const addLockerItem = ({ user, track }) => {
  return axios.post(TABLE_NAME, {
    fields: {
      Auth0_User_ID: user,
      Track: [track],
    }
  });
};

const removeLockerItem = ({ user, track }) => {
  return axios.delete(`${TABLE_NAME}/${track}`);
};

const getLockerByUser = user => {
  return axios.get(TABLE_NAME, {
    params: {
      fields: ['Track', 'Record_ID'],
      filterByFormula: `FIND("${user}", {Auth0_User_ID}) > 0`,
    },
  }).then(({ data }) => data.records);
};

export { addLockerItem, removeLockerItem, getLockerByUser };
