import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

import "./style.scss";

class Header extends Component {
  state = {
    searchInput: "",
    redirectToSearch: false,
    showMenu: false,
  };

  handleChange = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };

  doSearch = (e) => {
    e.preventDefault();
    this.setState({
      redirectToSearch: true,
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (event.target.id !== "hamburger") {
      this.setState({ showMenu: false });
    }
  };

  componentDidUpdate() {
    const { redirectToSearch } = this.state;

    if (redirectToSearch) {
      this.setState({
        redirectToSearch: false,
      });
    }
  }

  showRedirect = () => {
    const { redirectToSearch, searchInput } = this.state;
    return (
      redirectToSearch && (
        <Redirect
          to={`/dashboard/filter/${encodeURI(searchInput)}`}
          push={true}
        />
      )
    );
  };

  render() {
    const { user } = this.props;
    const { searchInput, showMenu } = this.state;

    return (
      <>
        {this.showRedirect()}

        <section className="header">
          <div className="container is-fluid">
            <div className="columns">
              <div className="logo-container">
                <Link to={`/dashboard`}>
                  <img
                    src="/img/logo-white/moodswing-logo-white.png"
                    alt="Moodswing.io"
                    className="logo"
                  />
                </Link>
              </div>
              <div className="column search-container">
                <div className="field search-field">
                  <form onSubmit={this.doSearch}>
                    <p className="control has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="Search"
                        onChange={this.handleChange}
                        value={searchInput}
                      />
                      <span className="icon is-small is-left">
                        <img src="/img/search.svg" alt="Search" />
                      </span>
                    </p>
                  </form>
                </div>
              </div>
              <div className="avatar-container">
                <span className="user-name">{user.fields.Name}</span>
              </div>
              <div
                className={`menu ${showMenu ? "active" : ""}`}
                onClick={() =>
                  this.setState({ showMenu: !this.state.showMenu })
                }
              >
                <a
                  role="button"
                  className="ms-navbar-burger"
                  aria-label="menu"
                  aria-expanded="false"
                >
                  <img src="/img/hamburger.png" alt="Menu" id="hamburger" />
                </a>
                <div className="ms-navbar-menu">
                  <a href="/logout">Logout</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
