import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { cacheTags, cacheProducers, cacheGenres, cacheGeographies, cacheMoods, cachePlaylists } from './actions/cache';
import * as localCache from './utils/localCache';
import { getTags } from './API/tags';
import { getProducers } from './API/producers';
import { getPlaylists } from './API/playlists';
import { getUser } from './API/auth';
import Dashboard from './Dashboard';
import Preview from './Preview';
import { setUserAction } from './actions/user';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './ErrorBoundary';
import { Helmet } from 'react-helmet';

import './style.scss';

const history = createBrowserHistory();

history.listen((location) => {
  window.ga('set', 'page', location.pathname + location.search);
  window.ga('send', 'pageview');
});

class App extends Component {
  state = {
    loginChecked: false,
  };

  doCacheTags = () => {
    const { cacheTags } = this.props;
    const tags = localCache.getItem('cache-tags');

    if (tags) {
      cacheTags(tags);
    } else {
      getTags().then(tags => {
        localCache.setItem('cache-tags', tags);
        cacheTags(tags);
      });
    }
  };

  doCacheProducers = () => {
    const { cacheProducers } = this.props;
    const producers = localCache.getItem('cache-producers');

    if (producers) {
      cacheProducers(producers);
    } else {
      getProducers().then(producers => {
        localCache.setItem('cache-producers', producers);
        cacheProducers(producers);
      });
    }
  };

  doCachePlaylists = () => {
    const { cachePlaylists } = this.props;
    const playlists = localCache.getItem('cache-playlists');

    if (playlists) {
      cachePlaylists(playlists);
    } else {
      getPlaylists().then(playlists => {
        localCache.setItem('cache-playlists', playlists);
        cachePlaylists(playlists);
      });
    }
  };

  componentWillMount = async () => {
    this.doCacheTags();
    this.doCacheProducers();
    this.doCachePlaylists();

    if (window.location.href.indexOf('preview') > -1) {
      this.setState({ loginChecked: true });
      return;
    }

    try {
      const user = await getUser();
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        this.props.setUserAction(user);
        this.setState({ loginChecked: true });
      } else {
        window.location.href = '/login';
      }
    } catch (e) {
      window.location.href = '/login';
    }
  }

  render() {
    const { loginChecked } = this.state;

    if (!loginChecked) {
      return <div style={{ position: 'absolute', width: '100%', height: '100%', color: '#ffffff', textAlign: 'center', backgroundColor: '#000000' }}>
        Checking credentials...
      </div>;
    }

    return (
      <ErrorBoundary>
        <Router history={history}>
          <div>
            <Helmet>
              <title>Moodswing Music</title>
            </Helmet>
            <Route exact path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/preview" component={Preview} />
          </div>
        </Router>
      </ErrorBoundary>
    );
  }
}


const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setUserAction: user => dispatch(setUserAction(user)),
  cacheTags: tags => dispatch(cacheTags(tags)),
  cacheProducers: producers => dispatch(cacheProducers(producers)),
  cacheGenres: genres => dispatch(cacheGenres(genres)),
  cacheGeographies: geographies => dispatch(cacheGeographies(geographies)),
  cachePlaylists: playlists => dispatch(cachePlaylists(playlists)),
  cacheMoods: moods => dispatch(cacheMoods(moods)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
