import React, { Component } from 'react';
import * as localCache from '../../utils/localCache';
import { changeSongAction } from '../../actions/audioPlayer';
import { cacheRecent } from '../../actions/cache';
import { getLockerByUser } from '../../API/lockers';
import { addLockerItem, removeLockerItem } from '../../API/lockers';
import { connect } from 'react-redux';
import _ from 'lodash';

import './style.scss';

class RecentActivity extends Component {
  state = {
    locker: [],
  }

  componentDidMount() {
    const recents = localCache.getItem('recents', []);
    if (recents.length) {
      const { cacheRecent } = this.props;
      recents.forEach(recent => {
        const { song, timestamp } = recent;
        cacheRecent(song, timestamp);
      });
    }

    setTimeout(this.getLocker, 1);
  }

  play = song => () => this.props.changeSongAction(song)

  toggleLocker = (song) => {
    const { user } = this.props;
    const { id } = song;
    const { locker } = this.state;

    const lockerItem = locker.filter(item => item.Track_ID === id);

    if (lockerItem && lockerItem.length > 0) {
      removeLockerItem({
        user: user.id,
        track: lockerItem[0].Record_ID,
      }).then(this.updateLocker);
    } else {
      addLockerItem({
        user: user.id,
        track: id,
      }).then(this.updateLocker);
    }
  };

  getLocker = () => {
    const { user } = this.props;

    getLockerByUser(user.id)
      .then(records => {
        const locker = records
          .filter(record => record && record.fields && record.fields.Track)
          .map(record => {
            return {
              Record_ID: record.fields.Record_ID,
              Track_ID: record.fields.Track[0],
            };
          });

        this.setState({ locker });
      });
  }

  updateLocker = () => {
    this.getLocker();
  }

  renderRecent = recent => {
    const { locker } = this.state;
    const { song, timestamp } = recent;

    return (
      <div
        className="recent"
        key={timestamp}>
        <span className="song-title" onClick={this.play(song)}>{song.name}</span>
        <span className="song-producer" onClick={this.play(song)}>{song.producer}</span>
        {locker.some(item => item.Track_ID === song.id) ?
          (<img src="/img/playlist_favorite_btn_active.svg" onClick={() => { this.toggleLocker(song) }} className="song-favorite" alt="Remove from Locker" />) :
          (<img src="/img/playlist_favorite_btn.svg" onClick={() => { this.toggleLocker(song) }} className="song-favorite" alt="Add to Locker" />)
        }
      </div>
    );
  };

  render() {
    const { recents } = this.props;

    let recentActivity = recents
      .sort(({ timestamp: timestampA }, { timestamp: timestampB }) => timestampB - timestampA);

    recentActivity = _.uniqBy(recentActivity, a => a.song.id)
      .map(this.renderRecent);

    return (
      <div className="recents-container">
        <h1>Activity</h1>
        <div className="recents">
          { recentActivity }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cache: { recents }, user }) => ({
  recents,
  user,
});

const mapDispatchToProps = dispatch => ({
  changeSongAction: song => dispatch(changeSongAction(song)),
  cacheRecent: (recent, timestamp) => dispatch(cacheRecent(recent, timestamp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentActivity);
