import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-rangeslider";
import { changeSongAction, togglePlayPause } from "../../actions/audioPlayer";
import { Howl, Howler } from "howler";
import _ from "lodash";
import { isMobile } from "react-device-detect";
import proImg from "../../Components/ProButton/Pro.png";

import "./style.scss";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      volume: 100,
      isPlaying: false,
      currentTime: "0:00",
      totalTime: "0:00",
      seekPosition: 0,
    };

    this.howl = null;
    this.timeInterval = null;
  }

  componentDidMount() {
    this.setState({
      volume: Howler.volume() * 100,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.url !== prevProps.url) {
      if (this.props.url) {
        this.howl && this.howl.unload();
        this.howl = new Howl({
          src: [this.props.url],
          loop: false,
        });

        this.setState({
          isReady: true,
        });

        this.timeInterval = setInterval(() => {
          const duration = this.howl.duration();
          const seekPosition = this.howl.seek();
          if (
            duration - seekPosition < 0.251 &&
            this.convertSecondsToMinutes(duration) !== "0:00"
          ) {
            this.playNext();
          }
          this.setState({
            seekPosition: isNaN(seekPosition) ? 0 : seekPosition,
            duration,
            currentTime: this.convertSecondsToMinutes(seekPosition),
            totalTime: this.convertSecondsToMinutes(duration),
          });
        }, 250);
      }
    }
  };

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  convertSecondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60) || 0;
    const secondsRemaining = Math.floor(seconds) % 60 || 0;

    return `${minutes}:${("0" + secondsRemaining).slice(-2)}`;
  }

  setVolume = (volume) => {
    Howler.volume(volume / 100);
    this.setState({ volume });
  };

  setMute = (val) => {
    if (!this.howl) {
      return;
    }
    this.howl.setMute(val);
  };

  setAudioCursor = (val) => {
    if (!this.howl) {
      return;
    }
    this.howl.seek(val);
  };

  formatSeekLabel = (val) => {
    return this.convertSecondsToMinutes(val);
  };

  startAudioSeek = () => {
    Howler.mute(1);
  };

  endAudioSeek = () => {
    Howler.mute(0);
  };

  toggleMute = (val) => {
    if (!this.howl) {
      return;
    }
    this.howl.toggleMute();
  };

  pause = () => {
    if (!this.howl) {
      return;
    }
    this.howl.playing() && this.howl.pause();
  };

  play = () => {
    if (!this.howl) {
      return;
    }
    !this.howl.playing() && this.howl.play();
  };

  playPause = () => {
    if (!this.howl) {
      return;
    }
    this.howl.playing() ? this.pause() : this.play();
  };

  playPrev = () => {
    const { playlist, id, changeSongAction } = this.props;
    if (!playlist) {
      return;
    }
    const songIndex = _.findIndex(playlist, { id });
    const prevSongIndex = songIndex > 0 ? songIndex - 1 : songIndex;
    const prevSong = {
      id: playlist[prevSongIndex].id,
      url:
        playlist[prevSongIndex].fields["Audio File"] &&
        playlist[prevSongIndex].fields["Audio File"][0]["url"]
          ? playlist[prevSongIndex].fields["Audio File"][0]["url"]
          : "",
      producer: playlist[prevSongIndex].fields["Producer Name Rollup"],
      name: playlist[prevSongIndex].fields["Title"],
      bpm: playlist[prevSongIndex].fields["BPM"] || "",
      pro: playlist[prevSongIndex].fields["Pro"],
      playlist,
    };

    changeSongAction(prevSong);
  };

  playNext = () => {
    const { playlist, id, changeSongAction } = this.props;
    if (!playlist) {
      return;
    }
    const songIndex = _.findIndex(playlist, { id });
    const nextSongIndex = songIndex < playlist.length - 1 ? songIndex + 1 : 0;
    const nextSong = {
      id: playlist[nextSongIndex].id,
      url:
        playlist[nextSongIndex].fields["Audio File"] &&
        playlist[nextSongIndex].fields["Audio File"][0]["url"]
          ? playlist[nextSongIndex].fields["Audio File"][0]["url"]
          : "",
      producer: playlist[nextSongIndex].fields["Producer Name Rollup"],
      name: playlist[nextSongIndex].fields["Title"],
      bpm: playlist[nextSongIndex].fields["BPM"] || "",
      pro: playlist[nextSongIndex].fields["Pro"],
      playlist,
    };

    changeSongAction(nextSong);
  };

  render() {
    const { isReady, seekPosition, duration, volume, currentTime, totalTime } =
      this.state;
    const { url, togglePlayPause, playState, producer, name, bpm, pro } =
      this.props;

    isReady && playState && this.play();
    isReady && !playState && this.pause();

    const isPlaying = this.howl && this.howl.playing();

    if (isMobile) {
      return (
        <div className="audio-player-wrapper">
          <div className="container is-fluid">
            <div className="columns">
              <div className="mobile-player-play">
                <span className="song-title">
                  {name ? name : <br />}
                  {this.props.pro ? (
                    <img class="proImg-class audio-pro" src={proImg} />
                  ) : (
                    ""
                  )}
                </span>

                <span className="song-title">
                  {producer ? producer : "Select a track"}
                </span>
              </div>

              <div className="column is-three-fifths fifths-mobile">
                <div className="columns controls">
                  <div className="column play-img-column">
                    <img
                      src="/img/audioplayer_prev_track.svg"
                      className="prev"
                      onClick={this.playPrev}
                      alt="Previous"
                    />
                    {isPlaying && (
                      <img
                        src="/img/playlist_play_btn_active.svg"
                        className="play"
                        onClick={togglePlayPause}
                        alt="Play/Pause"
                      />
                    )}
                    {!isPlaying && (
                      <img
                        src="/img/playlist_play_btn.svg"
                        className="play"
                        onClick={togglePlayPause}
                        alt="Play/Pause"
                      />
                    )}
                    <img
                      src="/img/audioplayer_next_track.svg"
                      className="next"
                      onClick={this.playNext}
                      alt="Next"
                    />
                  </div>
                </div>
                <div className="mobile-time-slider">
                  <div
                    className="column is-10"
                    style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
                  >
                    <div
                      className="cursor-slider"
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                      }}
                    >
                      <Slider
                        tooltip
                        min={0}
                        max={duration}
                        step={1}
                        value={seekPosition}
                        orientation="horizontal"
                        format={this.formatSeekLabel}
                        onChange={this.setAudioCursor}
                        onChangeStart={this.startAudioSeek}
                        onChangeComplete={this.endAudioSeek}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-one-fifth"></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="audio-player-wrapper">
          <div className="container is-fluid">
            <div className="columns">
              {
                <div className="column is-one-fifth">
                  <span className="song-title">
                    {name}

                    {this.props.pro ? (
                      <img class="proImg-class audio-pro" src={proImg} />
                    ) : (
                      ""
                    )}
                  </span>
                  <span className="song-producer">{producer}</span>
                  {bpm && <span className="song-bpm">{bpm} bpm</span>}
                </div>
              }
              <div className="column is-three-fifths">
                <div className="columns controls">
                  <div className="column">
                    <img
                      src="/img/audioplayer_prev_track.svg"
                      className="prev"
                      onClick={this.playPrev}
                      alt="Previous"
                    />
                    {isPlaying && (
                      <img
                        src="/img/playlist_play_btn_active.svg"
                        className="play"
                        onClick={togglePlayPause}
                        alt="Play/Pause"
                      />
                    )}
                    {!isPlaying && (
                      <img
                        src="/img/playlist_play_btn.svg"
                        className="play"
                        onClick={togglePlayPause}
                        alt="Play/Pause"
                      />
                    )}
                    <img
                      src="/img/audioplayer_next_track.svg"
                      className="next"
                      onClick={this.playNext}
                      alt="Next"
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-1 current-time">
                    {url && <span>{currentTime}</span>}
                  </div>
                  <div
                    className="column is-10"
                    style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
                  >
                    <div
                      className="cursor-slider"
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                      }}
                    >
                      <Slider
                        tooltip
                        min={0}
                        max={duration}
                        step={1}
                        value={seekPosition}
                        orientation="horizontal"
                        format={this.formatSeekLabel}
                        onChange={this.setAudioCursor}
                        onChangeStart={this.startAudioSeek}
                        onChangeComplete={this.endAudioSeek}
                      />
                    </div>
                  </div>
                  <div className="column is-1 total-time">
                    {url && <span>{totalTime}</span>}
                  </div>
                </div>
              </div>
              <div className="column is-one-fifth">
                <div className="columns volume-control-wrapper">
                  <div className="column is-2 is-offset-6 volume-container">
                    <img
                      src="/img/audioplayer_volume.svg"
                      className="volume"
                      alt="Volume"
                      onClick={this.toggleMute}
                    />
                  </div>
                  <div className="column is-4">
                    <Slider
                      className="volume-slider"
                      min={0}
                      max={100}
                      step={1}
                      value={volume}
                      orientation="horizontal"
                      onChange={this.setVolume}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  ...state.audioPlayer,
});

const mapDispatchToProps = (dispatch) => ({
  togglePlayPause: (song) => dispatch(togglePlayPause()),
  changeSongAction: (song) => dispatch(changeSongAction(song)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
