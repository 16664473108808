import React, { Component } from 'react';
import { connect } from 'react-redux';
import Category from '../Category';

import './style.scss';

class Mood extends Component {
	render() {
		return (
      <Category
        category="Mood"
        banner="/img/banner_mood.jpg"
        categoryName="Mood"
        categoryTitle="Mood"
        categoryLastUpdated="4 Days Ago"
        categoryDescription=""
        />
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Mood);
