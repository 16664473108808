import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import PlaylistTable from "../Playlist/playlistTable";
import ContentBanner from "../Category/ContentBanner";
import { getLockerByUser } from "../../API/lockers";
import { getTracksByIds } from "../../API/tracks";

import "./style.scss";

class MyLocker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trackList: [],
      latestTrackListDate: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      this.updateTrackList();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user.id !== user.id) {
      this.updateTrackList();
    }
  }

  updateTrackList = () => {
    const { user } = this.props;
    getLockerByUser(user.id).then((records) => {
      this.checkLatestUpdate(records);
      const ids = records
        .filter((record) => record && record.fields && record.fields.Track)
        .map((record) => {
          return record.fields.Track[0];
        });

      getTracksByIds({ ids }).then((trackList) => {
        this.setState({ trackList });
      });
    });
  };

  checkLatestUpdate = (trackList) => {
    const latestTrackList = _.orderBy(trackList, ["createdTime"], "desc");

    if (latestTrackList && latestTrackList.length > 0) {
      this.setState({
        latestTrackListDate: moment(latestTrackList[0].createdTime).fromNow(),
      });
    }
  };

  render() {
    const { user } = this.props;
    const { trackList, latestTrackListDate } = this.state;

    const { lockerBanner = "/img/banner_mood.jpg" } = user.fields._json[
      "https://moodswingmusic.io/app_metadata"
    ];

    return (
      <div className="playlist-page">
        <ContentBanner
          banner={lockerBanner}
          categoryName="My Locker"
          categoryTitle="My Locker"
          categoryDescription=""
        />

        <PlaylistTable playlistSongs={trackList} showLockerBtn={false} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyLocker);
