import React, { Component } from "react";
import { Route } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import RecentActivity from "./RecentActivity";
import Culture from "./Culture";
import Featured from "./Featured";
import Geography from "./Geography";
import Downloads from "./Downloads";
import Mood from "./Mood";
import Genre from "./Genre";
import MyLocker from "./MyLocker";
import Filter from "./Filter";
import Playlist from "./Playlist";
import BestFor from "./BestFor";
import AudioPlayer from "../Components/AudioPlayer/index";
import { setUserAction } from "../actions/user";
import { connect } from "react-redux";
import "./style.scss";

class Dashboard extends Component {
  componentDidMount() {
    const { user } = this.props;

    document.title = "Dashboard - Moodswing";

    if (!user || !user.id) {
      const localStorageUser = localStorage.getItem("user");

      if (!localStorageUser) {
        this.props.history.push("/login");
      } else {
        this.props.setUserAction(JSON.parse(localStorageUser));
      }
    }
  }

  render() {
    return (
      <>
        <Header />

        <section className="main">
          <div className="container is-fluid">
            <div className="columns">
              <div className="column sidebar-column">
                <Sidebar />
              </div>

              <div className="column main-content">
                <Route exact path="/" component={Featured} />
                <Route exact path="/dashboard" component={Featured} />
                <Route exact path="/dashboard/mood" component={Mood} />
                <Route exact path="/dashboard/genre" component={Genre} />
                <Route exact path="/dashboard/best-for" component={BestFor} />
                <Route exact path="/dashboard/culture" component={Culture} />
                <Route
                  exact
                  path="/dashboard/geography"
                  component={Geography}
                />
                <Route
                  exact
                  path="/dashboard/downloads"
                  component={Downloads}
                />
                <Route exact path="/dashboard/locker" component={MyLocker} />
                <Route
                  exact
                  path="/dashboard/filter/:value"
                  component={Filter}
                />
                <Route
                  exact
                  path="/dashboard/playlist/:id"
                  component={Playlist}
                />
              </div>

              <div className="column activity-column">
                <RecentActivity />
              </div>
            </div>
          </div>
        </section>

        <section className="audioplayer-section">
          <div className="container is-fluid">
            <div className="columns">
              <div className="column">
                <AudioPlayer />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (user) => dispatch(setUserAction(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
