import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTracksByFilter } from '../../API/tracks';
import PlaylistTable from '../Playlist/playlistTable';
import ContentBanner from '../Category/ContentBanner';

import './style.scss';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trackList: [],
    };
  }

  componentWillMount() {
    const { value } = this.props.match.params;

    getTracksByFilter({ value })
      .then(trackList => {
        this.setState({ trackList });
      });
  }

  componentWillReceiveProps(newProps) {
    const { value } = newProps.match.params;

    if (value !== this.props.match.params.value) {
      getTracksByFilter({ value })
        .then(trackList => {
          this.setState({ trackList });
        });
    }
  }

	render() {
    const { trackList } = this.state;

		return (
      <div className="category-page">
        <ContentBanner
          banner="/img/banner_search.jpg"
          categoryName="Search"
          categoryTitle="Search"
          categoryLastUpdated=""
          categoryDescription="" />

          <PlaylistTable playlistSongs={trackList} />
      </div>
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
