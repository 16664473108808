import axios from "axios";

const TABLE_NAME = "_tracks";

const getTracksByFilter = ({ filter, value }) => {
  const searchColumns = [
    "Title",
    "{Track Description}",
    "{Producer Name Rollup}",
    "BPM",
    "Rollup_Tag_Mood",
    "Rollup_Tag_TrackType",
    "Rollup_Tag_Genre",
    "Rollup_Tag_Instrument",
    "Rollup_Tag_Geography",
    "Pro",
  ];

  return axios
    .get(TABLE_NAME, {
      params: {
        filterByFormula: `AND(OR(${searchColumns
          .map((col) => `FIND(LOWER("${value}"), LOWER(${col})) > 0`)
          .join(", ")}), NOT({Hide From Search} = TRUE()))`,
      },
    })
    .then(({ data }) => data.records);
};

const getTracksByIds = ({ ids }) => {
  return axios
    .get(TABLE_NAME, {
      params: {
        filterByFormula: `OR(${ids
          .map((id) => `{Record_ID} = "${id}"`)
          .join(",")})`,
      },
    })
    .then(({ data }) => data.records);
};

export { getTracksByFilter, getTracksByIds };
