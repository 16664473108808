import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import PlaylistThumbnail from "../Playlist/playlistThumbnail";
import * as localCache from "../../utils/localCache";
import { getPlaylists } from "../../API/playlists";
import ContentBanner from "./ContentBanner";

import "./style.scss";

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlists: [],
      latestPlaylistDate: null,
    };
  }

  responsive = {
    0: { items: 1 },
    440: { items: 2 },
    768: { items: 3 },
    1024: { items: 4 },
  };

  componentWillMount = () => {
    const { category } = this.props;
    const allPlaylists = localCache.getItem("cache-playlists");

    if (allPlaylists) {
      const playlists = allPlaylists.filter(
        (playlist) => playlist.fields.Type === category
      );

      if (playlists) {
        this.setState({ playlists });
        this.checkLatestUpdate(playlists);
      } else {
        getPlaylists().then((playlists) => {
          this.setState({ playlists });
          this.checkLatestUpdate(playlists);
        });
      }
    }

  };

  checkLatestUpdate = (playlists) => {
    const latestPlaylist = _.orderBy(playlists, ["createdTime"], "desc");

    if (latestPlaylist && latestPlaylist.length > 0) {
      this.setState({
        latestPlaylistDate: moment(latestPlaylist[0].createdTime).fromNow(),
      });
    }
  };

  getBanner = (playlist) => {
    return playlist.fields["Banner Image"] &&
      playlist.fields["Banner Image"].length > 0
      ? playlist.fields["Banner Image"][0].url
      : "http://placehold.it/600/200";
  };

  getThumbnail = (playlist) => {
    return playlist.fields["Thumbnail Image"] &&
      playlist.fields["Thumbnail Image"].length > 0
      ? playlist.fields["Thumbnail Image"][0].url
      : "http://placehold.it/200/200";
  };

  render() {
    const { playlists, latestPlaylistDate } = this.state;
    const {
      banner,
      categoryName,
      categoryTitle,
      categoryDescription,
      playlistTitle = "Playlists",
    } = this.props;

    return (
      <div className="category-page">
        <ContentBanner
          banner={banner}
          categoryName={categoryName}
          categoryTitle={categoryTitle}
          categoryDescription={categoryDescription}
        />

        <h1>{playlistTitle}</h1>
        <div className="columns">
          <div className="column is-1"></div>
          <div className="column is-10">
            {_.orderBy(
              playlists,
              (record) => record.fields["Custom Order"] || 0
            ).map((item) => (
              <PlaylistThumbnail
                id={item.id}
                name={item.fields.Name}
                image={this.getThumbnail(item)}
                key={item.id}
              />
            ))}
          </div>
          <div className="column is-1"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
