import axios from 'axios';
import _ from 'lodash';

const TABLE_NAME = '_playlist_songs';

const getPlaylistSongs = () => {
  return axios.get(TABLE_NAME)
    .then(({ data }) => data.records);
};


const getPlaylistSongsById = playlistId => {
  return axios.get(TABLE_NAME, {
    params: {
      filterByFormula: `FIND("${playlistId}", {Playlist_Rollup})`,
    },
  }).then(({ data }) => _.orderBy(data.records, record => record.fields['Custom Order'] || 0));
};

export { getPlaylistSongs, getPlaylistSongsById };
