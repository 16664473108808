import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import "./style.scss";

class Sidebar extends Component {
  render() {
    const { user } = this.props;
    const DEMO_USER_ROLE_ID = "rol_WNHLm2ewgNZDv1eH";
    const FULL_USER_ROLE_ID = "rol_Saqeoh9Jrd7m5mUN";

    const showDownloadBtn = user.hasRole(FULL_USER_ROLE_ID);
    const showLockerBtn = user.hasRole(FULL_USER_ROLE_ID);
    // data-tip={!allowPrivateDownload ? "Request Beta Access to enable download." : null }>
    // <ReactTooltip />
    return (
      <>
        <nav>
          <ul className="sidebar-list">
            <li>
              <Link to="/dashboard">Home</Link>
            </li>
            <li>
              <Link
                to={!showLockerBtn ? "/dashboard" : "/dashboard/locker"}
                data-tip={
                  !showLockerBtn
                    ? "This feature is not available for Demo users."
                    : null
                }
              >
                My Locker
                <ReactTooltip />
              </Link>
            </li>
            <li>
              <Link to="/dashboard/mood">Mood</Link>
            </li>
            <li>
              <Link to="/dashboard/genre">Genre</Link>
            </li>
            <li>
              <Link to="/dashboard/best-for">Best For</Link>
            </li>
            {/* <li><Link to="/dashboard/geography">Geography</Link></li> */}
            <li>
              <Link
                to={!showDownloadBtn ? "/dashboard" : "/dashboard/downloads"}
                data-tip={
                  !showDownloadBtn
                    ? "This feature is not available for Demo users."
                    : null
                }
              >
                Downloads
                <ReactTooltip />
              </Link>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
