const cacheTags = tags => {
  return {
    type: 'CACHE_TAGS',
    tags,
  };
};

const cacheProducers = producers => {
  return {
    type: 'CACHE_PRODUCERS',
    producers,
  };
};

const cachePlaylists = playlists => {
  return {
    type: 'CACHE_PLAYLISTS',
    playlists,
  };
};

const cacheGenres = genres => {
  return {
    type: 'CACHE_GENRES',
    genres,
  };
};

const cacheMoods = moods => {
  return {
    type: 'CACHE_MOODS',
    moods,
  };
};
const cacheGeographies = geographies => {
  return {
    type: 'CACHE_GEOGRAPHIES',
    geographies,
  };
};

const cacheRecent = (song, timestamp) => ({
  type: 'ADD_TO_RECENTS_CACHE',
  song,
  timestamp,
});

export { cacheTags, cacheProducers, cachePlaylists, cacheGenres, cacheMoods, cacheGeographies, cacheRecent };
