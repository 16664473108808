import React, { Component } from 'react';
import './style.scss';

class ContentBanner extends Component {
  constructor(props) {
    super(props);

    this.bannerRef = React.createRef();
    this.state = {
      bannerHeight: 417,
    };
  }

  componentDidMount() {
    this.updateBannerSize();
    window.addEventListener("resize", this.updateBannerSize);
  }

  updateBannerSize = () => {
    if (this.bannerRef.current) {
      this.setState({ bannerHeight: this.bannerRef.current.offsetWidth * 0.2486 });
    }
  }

	render() {
    const { banner, categoryName, categoryTitle, categoryLastUpdated, categoryDescription } = this.props;
    const { bannerHeight } = this.state;
    const showButtons = false;

		return (
      <>
        <div ref={this.bannerRef} className="category-main-banner" style={{ height: bannerHeight, backgroundImage: `url(${banner})` }}></div>
        <div className="category-main-banner-content" style={{ height: bannerHeight, marginLeft: '26.86%', padding: 20 }}>
          <span className="category-title">{categoryTitle}</span>
          {categoryLastUpdated && (
            <span className="category-last-updated">Updated <strong>{categoryLastUpdated}</strong></span>
          )}
          <span className="category-description">{categoryDescription}</span>
          {showButtons && (
          <div className="play-all-container">
            <a className="play-all-button">PLAY ALL</a>
            <img src="/img/download_all_btn.svg" />
          </div>
          )}
        </div>
      </>
		);
	}
}

export default ContentBanner;
