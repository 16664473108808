const changeSongAction = song => {
  return {
    type: 'CHANGE_SONG',
    ...song,
  };
};

const togglePlayPause = () => {
  return {
    type: 'TOGGLE_PLAY_PAUSE',
    toggle: true,
  };
};

const changeWaveColorAction = waveColor => {
  return {
    type: 'CHANGE_WAVE_COLOR',
    waveColor,
  };
};

const changeProgressColorAction = progressColor => {
  return {
    type: 'CHANGE_PROGRESS_COLOR',
    progressColor,
  };
};

export { togglePlayPause, changeSongAction, changeWaveColorAction, changeProgressColorAction };
