import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getPlaylistsById } from "../../API/playlists";
import { getPlaylistSongsById } from "../../API/playlistSongs";
import { getTracksByIds } from "../../API/tracks";
import PlaylistTable from "./playlistTable";
import ContentBanner from "../ContentBanner";

import "./style.scss";

class Playlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlist: {},
      playlistSongs: [],
    };
  }

  componentWillMount() {
    this.getPlaylist();
  }

  getPlaylist() {
    const { match } = this.props;
    getPlaylistsById(match.params.id).then((playlists) => {
      this.setState({ playlist: playlists[0] });
    });
    getPlaylistSongsById(match.params.id).then((playlistSongsTable) => {
      const ids = _.map(playlistSongsTable, (p) => p.fields.Track_Rollup);
      getTracksByIds({ ids }).then((playlistSongsResponse) => {
        const playlistSongs = [];
        for (let i = 0; i < playlistSongsTable.length; i++) {
          for (let j = 0; j < playlistSongsResponse.length; j++) {
            if (
              playlistSongsTable[i].fields["Track_Rollup"] ===
              playlistSongsResponse[j].id
            ) {
              playlistSongs.push(playlistSongsResponse[j]);
            }
          }
        }
        this.setState({ playlistSongs });
      });
    });
  }

  render() {
    const { playlist, playlistSongs } = this.state;
    const banner =
      playlist &&
      playlist.fields &&
      playlist.fields["Banner Image"] &&
      playlist.fields["Banner Image"][0]
        ? playlist.fields["Banner Image"][0].url
        : "/img/banner_mood.jpg";
    const categoryName = `${
      playlist && playlist.fields && playlist.fields.Type
        ? playlist.fields.Type
        : ""
    } / ${
      playlist && playlist.fields && playlist.fields.Name
        ? playlist.fields.Name
        : ""
    }`;
    const categoryTitle =
      playlist && playlist.fields && playlist.fields.Name
        ? playlist.fields.Name
        : "";
    const categoryDescription =
      playlist && playlist.fields && playlist.fields.Description
        ? playlist.fields.Description
        : "";

    return (
      <div className="playlist-page">
        {playlist && playlist.fields && !playlist.fields["Featured"] && (
          <ContentBanner
            banner={banner}
            categoryName={categoryName}
            categoryTitle={categoryTitle}
            categoryLastUpdated={""}
            categoryDescription={categoryDescription}
          />
        )}
        {playlist && playlist.fields && playlist.fields["Featured"] && (
          <ContentBanner
            banner={banner}
            categoryName={""}
            categoryTitle={""}
            categoryLastUpdated={""}
            categoryDescription={""}
          />
        )}

        <PlaylistTable
          playlistFields={playlist && playlist.fields ? playlist.fields : {}}
          playlistSongs={playlistSongs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
