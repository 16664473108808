const getItemRaw = key => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : false;
}

/**
 * Checks expiration of a cache object and removes it if expired
 * @param  {string} key
 * @return {boolean}
 */
const checkExpiration = key => {
  const item = getItemRaw(key);

  if (!item) {
    return false;
  } else if (item.expiration < Date.now()) {
    removeItem(key);
    return false;
  } else {
    return true;
  }
};

/**
 * Removes item from local storage
 * @param {string} key
 */
const removeItem = key => {
  localStorage.removeItem(key);
};

/**
 * Retrieves cache object by key
 * @param {string} key
 * @param {*} defaultValue
 */
const getItem = (key, defaultValue) => {
  const item = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
  return item && checkExpiration(key) ? item.value : defaultValue;
};

/**
 * Sets a key-value pair to the cache object with expiration
 * @param {string} key
 * @param {*} value
 * @param {number} expiration in milliseconds
 */
const setItem = (key, value, expiration = 1000 * 60 * 60 * 24) => {
  localStorage.setItem(key, JSON.stringify({
    expiration: Date.now() + expiration,
    value,
  }));

  return value;
};

/**
 * Adds a key-value pair to a collection in the cache object
 * @param {string} collectionName
 * @param {object} objectToAdd
 */
const addToCollection = (collectionName, objectToAdd, expiration = 1000 * 60 * 60 * 24) => {
  const collection = getItem(collectionName, []);
  collection.push(objectToAdd);
  setItem(collectionName, collection, expiration);
};

export { getItem, setItem, removeItem, addToCollection };
