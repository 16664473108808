const initialState = {
  id: null,
  fields: {},
  createdTime: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return Object.assign({}, state, {
        id: action.user.id,
        fields: action.user,
        hasRole: (roleId) => {
          const { roles = [] } = action.user;

          for (let i = 0; i < roles.length; i++) {
            if (roles[i].id === roleId) {
              return true;
            }
          }

          return false;
        },
      });
    default:
      return state
  }
};

export default user;
