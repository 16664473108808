import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>
            An error has occurred. Please reload your browser and try again, or click <a href="mailto:support@moodswingmusic.io?subject=Errors">here</a> to email support.
          </h1>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
