import React, { Component } from 'react';
import { connect } from 'react-redux';
import Category from '../Category';

import './style.scss';

class Genre extends Component {
	render() {
		return (
      <Category
        category="Genre"
        banner="/img/banner_genre.jpg"
        categoryName="Genre"
        categoryTitle="Genre"
        categoryLastUpdated="4 Days Ago"
        categoryDescription=""
        />
		);
	}
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Genre);
