import React, { Component } from "react";
import { Route } from "react-router-dom";
import Header from "./Header";
import Playlist from "./Playlist";
import AudioPlayer from "../Components/AudioPlayer/index";
import { connect } from "react-redux";
import "./style.scss";

class Preview extends Component {
  componentDidMount() {
    document.title = "Playlist Preview - Moodswing";
  }

  render() {
    return (
      <div className="preview-page">
        <Header />

        <section className="main">
          <div className="container is-fluid">
            <div className="columns">
              <div className="column sidebar-column"></div>
              <div className="column main-content">
                <Route exact path="/preview/:id" component={Playlist} />
              </div>
              <div className="column activity-column"></div>
            </div>
          </div>
        </section>

        <section className="audioplayer-section">
          <div className="container is-fluid">
            <div className="columns">
              <div className="column extra-space-color">
                <AudioPlayer />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
