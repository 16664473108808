import React, { Component } from "react";
import { connect } from "react-redux";
import PlaylistItem from "./playlistItem";
import { changeSongAction, togglePlayPause } from "../../actions/audioPlayer";
import { getLockerByUser } from "../../API/lockers";
import { cacheRecent } from "../../actions/cache";
import * as localCache from "../../utils/localCache";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import "./style.scss";

class PlaylistTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        key: null,
        value: null,
      },
      locker: [],
    };
  }

  filterSongs = () => {
    const {
      filter: { key, value },
    } = this.state;
    const { playlistSongs } = this.props;

    if (!key || !value) {
      return playlistSongs;
    } else {
      return playlistSongs.filter((p) => {
        return p[key] === value;
      });
    }
  };

  getProducerName = (track) => {
    if (!this.props.cache) {
      return "N/A";
    }
    const Record_ID = track.fields.Producer[0];
    const {
      cache: { producers },
    } = this.props;
    const producer = _.find(producers, (p) => {
      return p.fields.Record_ID === Record_ID;
    });

    return producer.fields["Display Name"] || "N/A";
  };

  componentWillReceiveProps() {
    this.getLocker();
  }

  getLocker = () => {
    const { user } = this.props;
    getLockerByUser(user.id).then((records) => {
      const locker = records
        .filter(
          (record) =>
            record &&
            record.fields &&
            record.fields.Track &&
            record.fields.Record_ID
        )
        .map((record) => {
          return {
            Record_ID: record.fields.Record_ID,
            Track_ID: record.fields.Track[0],
          };
        });

      this.setState({ locker });
    });
  };

  updateLocker = () => {
    this.getLocker();
  };

  handleOnPlay = (track) => {
    const {
      audioPlayer,
      togglePlayPause,
      changeSongAction,
      cacheRecent,
    } = this.props;
    const playlist = this.filterSongs();

    // const songIndex = _.findIndex(playlist, { id: track.id });

    // const prevSongIndex = songIndex > 0 ? songIndex - 1 : songIndex;
    // const nextSongIndex = songIndex < playlist.length - 1 ? songIndex + 1 : songIndex;

    // const prevSong = {
    //   id: playlist[prevSongIndex].id,
    //   url: playlist[prevSongIndex].fields['Audio File'][0]['url'],
    //   producer: playlist[prevSongIndex].fields['Producer Name Rollup'],
    //   name: playlist[prevSongIndex].fields['Title'],
    //   bpm: playlist[prevSongIndex].fields['BPM'] || '',
    // };

    // const nextSong = {
    //   id: playlist[nextSongIndex].id,
    //   url: playlist[nextSongIndex].fields['Audio File'][0]['url'],
    //   producer: playlist[nextSongIndex].fields['Producer Name Rollup'],
    //   name: playlist[nextSongIndex].fields['Title'],
    //   bpm: playlist[nextSongIndex].fields['BPM'] || '',
    // };

    const song = {
      pro: track.fields["Pro"],
      id: track.id,
      url: track.fields["Audio File"][0]["url"],
      producer: track.fields["Producer Name Rollup"],
      name: track.fields["Title"],
      bpm: track.fields["BPM"] || "",
      playlist,
    };

    if (audioPlayer && audioPlayer.url === song.url) {
      togglePlayPause();
    } else {
      changeSongAction(song);
    }

    // Add songs to recents cache
    const timestamp = Date.now();
    cacheRecent(song, timestamp);
    localCache.addToCollection(
      "recents",
      { song, timestamp },
      365 * 24 * 60 * 60 * 1000
    );
  };

  render() {
    const { cache, showLockerBtn = true, showDownloadBtn = true } = this.props;
    const { locker } = this.state;

    return (
      <table className="table is-fullwidth playlist-table">
        <thead>
          <tr>
            <th className="play"></th>
            <th className="track">TRACK</th>
            <th className="producer">PRODUCER/LABEL</th>
            <th className="bpm">BPM</th>
            {/* <th className="pro">PRO</th> */}
            {/* check if demo user and check if song is downloadable */}
            <th
              className={`locker ${showLockerBtn ? "" : "disabled"}`}
              data-tip={
                !showLockerBtn
                  ? "This feature is not available for Demo users."
                  : null
              }
            >
              SAVE TO LOCKER
              <ReactTooltip />
            </th>
            <th
              className={`download ${showDownloadBtn ? "" : "disabled"}`}
              data-tip={
                !showDownloadBtn
                  ? "This feature is not available for Demo users."
                  : null
              }
            >
              DOWNLOAD
              <ReactTooltip />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.filterSongs().map((track) => (
            <PlaylistItem
              updateLocker={this.updateLocker}
              locker={locker}
              track={track}
              onPlay={() => {
                this.handleOnPlay(track);
              }}
              id={track.id}
              name={track.fields.Title}
              producer={this.getProducerName(track)}
              bpm={track.fields.BPM}
              pro={track.fields["Pro"]}
              cultureTags={track.fields["Culture Tags Rollup"]}
              geographyTags={track.fields["Geography Tags Rollup"]}
              showLockerBtn={showLockerBtn}
              showDownloadBtn={showDownloadBtn}
              tags={
                track.fields.Tags
                  ? track.fields.Tags.map((tagId) => {
                      if (cache && cache.tags) {
                        const tag = _.find(cache.tags, (cachedTag) => {
                          return cachedTag.id === tagId;
                        });
                        return tag ? tag.fields.Name : tagId;
                      }

                      return tagId;
                    })
                  : []
              }
              url={
                track.fields["Audio File"]
                  ? track.fields["Audio File"][0].url
                  : `https://s3.amazonaws.com/moodswing-stage/01+Charlie's+Manifesto.mp3`
              }
              key={track.id}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => ({
  audioPlayer: state.audioPlayer,
  user: state.user,
  cache: state.cache,
});

const mapDispatchToProps = (dispatch) => ({
  togglePlayPause: () => dispatch(togglePlayPause()),
  changeSongAction: (song) => dispatch(changeSongAction(song)),
  cacheRecent: (recent, timestamp) => dispatch(cacheRecent(recent, timestamp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistTable);
